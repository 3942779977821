<template>
  <b-container style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" title="empreendimentos" :menu="menu" />
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-8">
              <div class="form-group d-flex">
                <input
                  type="search"
                  class="form-control campo-procurar"
                  placeholder="Procurar"
                />
                <button type="submit" class="btn btn-info">Buscar</button>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
              <div class="form-group d-flex">
                <b-link :to="{ name: 'EnterpriseNew' }" class="ml-auto">
                  <button type="submit" class="btn btn-dark">
                    <i class="far fa-building mr-2"></i>Novo
                  </button>
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card-list-legenda">
            <div class="card-body-list-legend d-none d-lg-block">
              <div class="col-12 p-0 align-self-center">
                <div class="col-12">
                  <div class="row">
                    <div
                      class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status"
                    >
                      <p class="text-truncate title-legend-list">
                        <i
                          class="fa fa-circle color-ico-legenda font-12"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Status"
                        ></i>
                      </p>
                    </div>
                    <div
                      class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img"
                    >
                      <p
                        class="text-truncate title-legend-list"
                        style="width: 45px"
                      >
                        &nbsp;
                      </p>
                    </div>
                    <div
                      class="col-5 col-md-7 col-lg-8 p-0 order-3 align-self-center"
                    >
                      <div class="col-12">
                        <div class="row">
                          <div
                            class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list"
                          >
                            <p class="text-truncate title-legend-list">
                              Imóvel
                            </p>
                          </div>
                          <div
                            class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list"
                          >
                            <p class="text-truncate title-legend-list">Tipo</p>
                          </div>
                          <div
                            class="col-12 col-md-6 col-lg-4 align-self-center pad-content-list d-none d-md-block"
                          >
                            <p class="text-truncate title-legend-list">
                              ÁREA DO TERRENO (M²)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-4 col-md-3 col-lg-2 align-self-center pad-content-list order-4"
                    >
                      <p class="text-truncate title-legend-list">Status</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-list" v-for="item in enterprises" :key="item.id">
            <div class="card-body-list">
              <div class="col-12 p-0 align-self-center">
                <div class="col-12">
                  <div class="row">
                    <div
                      class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block"
                    >
                      <i
                        class="fa fa-circle text-success font-12"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Status"
                      ></i>
                    </div>
                    <div
                      class="col-auto col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img"
                    >
                      <img
                        v-if="item.image"
                        :src="item.image"
                        alt="user"
                        class="rounded-circle card-body-list-img"
                      />
                    </div>
                    <div
                      class="col-5 col-md-8 col-lg-8 p-0 order-3 align-self-center"
                    >
                      <div class="col-12">
                        <div class="row">
                          <div
                            class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list"
                          >
                            <h6 class="text-truncate cod">
                              <span>CÓD.:</span> {{ item.id }}
                            </h6>
                            <b-link
                              :to="{
                                name: 'EnterpriseEdit',
                                params: { id: item.id },
                              }"
                              class=""
                            >
                              <h6 class="text-truncate card-body-list-title">
                                {{ item.name }}
                              </h6>
                            </b-link>
                          </div>
                          <div
                            class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list"
                          >
                            <h6 class="text-truncate empreendi">
                              Empreendimento
                            </h6>
                          </div>
                          <div
                            class="col-auto col-md-6 col-lg-4 align-self-center pad-content-list d-none d-md-block"
                          >
                            <h6 class="text-truncate torre">
                              {{ item.area }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-4 col-md-1 col-lg-1 order-3 p-lg-3 p-0 align-self-center text-muted"
                    >
                      <h6 class="status-imovel">
                        <small
                          class="badge form-text text-white"
                          v-if="item.status"
                          :style="{ 'background-color': item.status.color }"
                          >{{ item.status.name }}</small
                        >
                      </h6>
                    </div>

                    <div
                      class="col-1 col-md-1 text-right col-lg-1 ml-5 order-3 p-0 align-self-center text-muted"
                    >
                      <a
                        class="btn btn-danger text-white rounded-circle btn-circle font-16 ml-2"
                        href="javascript:void(0);"
                        @click="removeEnterprise(item.id)"
                        ><i class="far fa-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <BasePagination
              v-if="enterprises.length > 0"
              :totalPages="pagination.totalPages"
              :activePage="pagination.currentPage"
              @to-page="toPage"
              @per-page="perPage"
            />
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import EnterpriseService from "@/services/resources/EnterpriseService";
const service = EnterpriseService.build();

export default {
  data() {
    return {
      enterprises: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0,
      },
      pages: [
        {
          name: "dashboard",
          to: "Dashboard",
        },
        {
          name: "empreendimentos",
          to: "Enterprise",
        },
      ],
      menu: [
        {
          name: "Novo",
          to: "EnterpriseNew",
        },
        {
          name: "Empreendimentos",
          to: "Enterprise",
        },
      ],
    };
  },
  methods: {
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchEnterprises();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchEnterprises();
    },
    removeEnterprise(id) {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja excluir esse empreendimento?")
        .then((value) => {
          if (value) {
            let data = {
              id: id,
            };

            service
              .destroy(data)
              .then((resp) => {
                this.$bvToast.toast(
                  "Seu empreendimento foi excluído com sucesso!",
                  {
                    title: "Excluir empreendimento",
                    autoHideDelay: 5000,
                    type: "success",
                  }
                );
                this.fetchEnterprises();
              })
              .catch((err) => {
                this.$bvToast.toast("Sua enterprise não pode ser excluída.", {
                  title: "Excluir empreendimento",
                  autoHideDelay: 5000,
                  type: "danger",
                });
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    fetchEnterprises() {
      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };

      service
        .search(data)
        .then((resp) => {
          this.enterprises = resp.data;
          this.pagination.totalPages = resp.last_page;
          this.pagination.itens = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goEdit(id) {
      this.$router.push({ name: "EnterpriseEdit", params: { id: id } });
    },
  },
  mounted() {
    this.fetchEnterprises();
  },
};
</script>
<style scoped lang="scss">
.bg {
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;

  .item {
    padding: 10px 0;
    border-bottom: 1px #ccc solid;
  }
}
</style>
